БеуьздфеуЮ
<template>
  <layout width="420" :dividers="false">
    <template slot="header">
      {{ title }}
    </template>

    <template>
      <v-form>
        <v-text-field
          v-for="(item, index) in state"
          :key="index"
          v-model="item.value"
          :label="item.label"
        />
      </v-form>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click.stop="$emit('close')">
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        depressed
        :loading="loading"
        @click.stop="handleConfirm"
      >
        {{ confirmText }}
      </v-btn>
    </template>
  </layout>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api';
import layout from './PopupLayoutDefault';
export default {
  name: 'PopupValuesEdit',
  components: {
    layout
  },
  props: {
    title: {
      type: String,
      default: 'Edit value'
    },
    value: {
      type: Array,
      default: () => [
        {
          label: 'Label',
          value: ''
        }
      ]
    },
    confirmText: {
      type: String,
      default: 'Save'
    },
    onConfirm: {
      type: Function,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const state = ref([]);
    const handleCancel = () => {
      emit('close');
    };
    const handleConfirm = async () => {
      try {
        loading.value = true;
        await props.onConfirm(state.value);
        loading.value = false;
        emit('close');
      } finally {
        loading.value = false;
      }
    };
    onMounted(() => {
      state.value = props.value;
    });
    return {
      handleConfirm,
      handleCancel,
      loading,
      state
    };
  }
};
</script>

<style lang="sass" scoped>
::v-deep
  .form-subheader:first-child
    margin-top: -16px
</style>
